<template>
  <BaseModal
    no-padding
    no-exit
    card-width="600px"
  >
    <template #toolbar>
      <div class="w-full flex items-center justify-between">
        <div class="flex items-center gap-2">
          <NewReportIcon class="text-icon-normal" />
          <BaseText
            class="text-text-muted"
            type="label"
            size="sm"
          >
            <span v-if="stepper.step === 1">Create New Report</span>
            <span v-else-if="stepper.step === 2">Name & Describe {{ reportForm.type?.headerName }}</span>
          </BaseText>
        </div>
        <button @click="$emit('close')">
          <TagRemoveIcon stroke="#5E6678" />
        </button>
      </div>
    </template>

    <template #default>
      <!-- FIRST STEP -->
      <div
        v-if="stepper.step == 1"
        class="flex items-center w-full h-full border-t border-border-normal mt-4"
      >
        <div
          v-for="(reportType,index) of reportTypes"
          :key="reportType.key"
          class="report-type-btn group flex flex-col h-full hover:bg-background-normal w-full justify-between cursor-pointer"
          :class="[
            reportForm.type.key === reportType.key ? 'bg-background-normal' : '',
            index === 0 ? 'border-r border-border-normal' : ''
          ]"
          @click="reportForm.type = reportType"
        >
          <div class="relative h-48">
            <img
              class="report-type-img"
              :class="[reportForm.type.key === reportType.key ? 'img-type-active' : '']"
              :src="reportType.img"
            >
          </div>
          <div
            class="px-4 py-7 text-center"
          >
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted group-hover:text-text-muted"
            >
              {{ reportType.name }}
            </BaseText>
            <BaseText
              size="xs"
              class="text-text-normal group-hover:text-text-muted px-8"
            >
              {{ reportType.desc }}
            </BaseText>
          </div>
        </div>
      </div>
      <!-- SECOND STEP -->
      <div
        v-if="stepper.step == 2"
        class="p-5 flex flex-col gap-3"
      >
        <!-- Name -->
        <div class="flex flex-col gap-1 w-full">
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Report Name
          </BaseText>

          <input
            v-model="reportForm.name"
            placeholder="Creative Highlights"
            class="form-input fade-in min-w-0 w-full px-2 py-1.5"
          >
        </div>
        <!-- Desc -->
        <div class="flex flex-col gap-1 w-full col-span-4">
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Description <span class="text-text-subdued">(Optional)</span>
          </BaseText>

          <input
            v-model="reportForm.desc"
            placeholder="Type a description of this report"
            class="form-input fade-in min-w-0 w-full px-2 py-1.5"
          >
        </div>
      </div>
      <div class="w-full h-0.5 border-t border-border-normal mb-3" />
      <div class="flex justify-between items-center px-5 pb-5">
        <button
          v-if="stepper.step == 1"
          class="p-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
          @click="$emit('close')"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Cancel
          </BaseText>
        </button>
        <button
          v-else
          class="p-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
          @click="onBackStep"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Back
          </BaseText>
        </button>
        <!-- Stepper -->
        <div>
          <BaseText
            size="sm"
            class="text-text-normal"
          >
            {{ stepper.step }}/{{ stepper.totalSteps }}
          </BaseText>
        </div>
        <button
          v-if="stepper.step !== stepper.totalSteps"
          :disabled="disableNext"
          class="next-btn black-btn-primary transition-colors text-white bg-black px-3 py-1.5 rounded-md"
          @click="onNextStep"
        >
          <BaseText
            type="label"
            size="sm"
          >
            Next
          </BaseText>
        </button>
        <button
          v-else
          class="next-btn black-btn-primary transition-colors text-white bg-black px-3 py-1.5 rounded-md"
          :disabled="isLoading || disableNext"
          @click="onFinish"
        >
          <BaseLoadingSpinnerCircle
            v-if="isLoading"
          />
          <BaseText
            v-else
            type="label"
            size="sm"
          >
            Finish
          </BaseText>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import LensAPI from '@/api/lens'
// Icons
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'
import NewReportIcon from '../globals/Icons/LensIcons/NewReportIcon.vue'

export default {
  name: 'NewReportModal',
  components: {
    NewReportIcon,
    TagRemoveIcon
  },
  props: {
    lensId: {
      type: String,
      required: true
    }
  },
  data () {
    const reportTypes = [
      {
        name: 'Top Performing',
        desc: 'Analyze which ads are currently performing their best.',
        img: require('../../assets/images/lens-report-top.png'),
        key: 'top-performing',
        headerName: 'Top Performing Report'
      },
      {
        name: 'Comparaitive Report',
        desc: 'Group & compare multiple sets of creative against each other.',
        img: require('../../assets/images/lens-report-comparitive.png'),
        key: 'comparaitive',
        headerName: 'Comparaitive Report'
      }

    ]
    return {
      reportTypes,

      stepper: {
        step: 1,
        totalSteps: 2
      },
      isLoading: false,

      reportForm: {
        type: {},
        name: '',
        desc: ''
      }
    }
  },
  computed: {
    disableNext () {
      if (this.stepper.step === 1) return !this.reportForm.type?.key
      if (this.stepper.step === 2) return !this.reportForm.name

      return false
    }
  },
  methods: {
    onNextStep () {
      this.stepper.step++
    },
    onBackStep () {
      this.stepper.step--
    },
    async onFinish () {
      this.isLoading = true
      const { name, desc, type } = this.reportForm
      // Use default preset on new reports
      const useDefault = true
      // New reports default to last 7 days
      const dateFilter = [
        [
          {
            key: 'event_date',
            operation: 'greater_than',
            value: 'last_7_days'
          }
        ],
        [
          {
            key: 'event_date',
            operation: 'less_than',
            value: 'today'
          }
        ]
      ]
      try {
        const { data: lensData } = await LensAPI.Lens.get(this.lensId)
        const report = await LensAPI.LensReport.create({
          lens_id: this.lensId,
          name,
          description: desc,
          type: type.key,
          filters: dateFilter,
          table_columns: [],
          group_by: 'ad_name',
          graph_type: 'grid',
          click_attribution_window: lensData.click_attribution_window,
          view_attribution_window: lensData.view_attribution_window,
          selected_columns: {
            bar: ['spend', 'roas', 'ctr', 'cpm'],
            line: ['spend', 'roas'],
            grid: ['spend', 'roas', 'ctr', 'cpm']
          },
          selected_graph_rows: {
            bar: 4,
            line: 4,
            grid: 4
          },
          sorted_column: { by: 'spend', order: 'desc' },
          table_config: {
            showStatus: true,
            showTags: false,
            colorFormat: 'positive_negative'
          }
        }, useDefault)
        const reportId = report.data?.id
        this.$router.push({ name: 'LensReport', params: { lensId: this.lensId, reportId } })
        this.$emit('close')
      } catch (e) {
        this.$emit('close')
        this.$showAlert({ type: 'error', message: 'Unable to create a report for this lens' })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
.form-input {
    color: #06070F;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
    transition: box-shadow 150ms ease-in-out;
}

.form-input:focus {
    outline: none;
    box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}

.form-input::placeholder {
    color: #5E6678;
    transition: color 150ms ease-in-out;
    opacity: 0.9;
}

.form-input:hover::placeholder {
    color: #303546;
}

.form-input:focus::placeholder {
    color: #C1C7D0;
}

.report-type-btn {
    overflow: hidden;
    transition: all 150ms ease-in-out;
}

.report-type-img{
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -60px;
  width: 255px;
  height: 255px;
  transition: filter 150ms ease-in-out;
}
.report-type-btn:hover >>> .report-type-img{
  filter: brightness(90%);
}
.img-type-active{
  filter: brightness(90%);
}

.next-btn:disabled {
    color: #a4abb8 !important;
    background-color: #F6F8FA !important;
    box-shadow: none;
}
</style>
